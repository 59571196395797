import Utility from '../../../shared/utility.js';
import EncryptUtility from './encrypt-utility';
import AddFavourite from "./addFav";
import breadcrumbComp from '../../common/breadcrumb-comp';
import PrinterDetails from '../../common/printer-details';
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList:[],
      mainPage:"",
      subPage:"",
      pageUrl:"",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      isPrinter: true,
      unitIdentifier: '',
      validatedUnitIdent: false,
      isFormValid: false,
      pass: null,
      unitIdentifyValid: false,
      btnName: '',
      searchClicked: false,
      mainKey: null,
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
      max50Rule: [ 
        (v) => (v || '').length <= 50 || "Max Length of 50 character",
        (v) => !!v || 'Field is required'
      ],
      max5Rule: [ 
        (v) => (v || '').length <= 5 || "Max Length of 5 character",
        (v) => !!v || 'Field is required'
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
  },
  methods: {
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd,this.userId,this.route)  
    },
    //Reset Function
    resetFunction() {
      this.unitIdentifier = '';
      this.validatedUnitIdent = false;
      this.pass = null;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.isFormValid = false;
      this.btnName = '';
      this.searchClicked = false;
      this.mainKey = null;
    },
    //Searching the Identifier
    searchIdentifer(){
      if (!this.$refs.UnitIdentifierForm.validate()) return false;
      this.pass = 1;
      if(EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined){
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if(EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined){
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        main_key: 0,
        UserId: parseInt(this.userId),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/warranty_change', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.btnName = responsedata.Info[0].btn_label;
            this.mainKey = responsedata.Info[0].main_key;
            this.searchClicked = true;
            this.isFormValid = true;
            this.validatedUnitIdent = true;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //On Change click event
    onChangeClick(){
      this.pass = 2;
      if(EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined){
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if(EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined){
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let changeUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        main_key: parseInt(this.mainKey),
        UserId: parseInt(this.userId),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/warranty_change',changeUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            if (this.primaryName !== null && this.primaryName !== undefined) {
              PrinterDetails.printLabelData(responsedata.Info[0].label,this.primaryName);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.resetFunction();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
